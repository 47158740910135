import { useState } from 'react';
import { Row,Col,Card,Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function OfficerList ({items}) {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };

    const hoverStyle = {
        border: '2px solid #999', // Change the border color and width
        backgroundColor: '#EAEAEA', // Change the background color on hover
        transition: 'border 0.3s, background-color 0.3s', // Add smooth transition
      };


    return (
    <>
    <Row>
      {items.map((item, index) => (
        item.officer_name && (
          <Col md={3} key={index}>
            <Link style={{ 'textDecoration' : 'none' }}  to={`/directories/officer/${item.id}`}>
              <Card
                className={`p-2 mb-3`}
                style={hoveredIndex === index ? hoverStyle : {}}
                onMouseEnter={() => handleMouseEnter(index)} // Handle mouse enter
                onMouseLeave={handleMouseLeave} // Handle mouse leave
              >
                <Image
                  className="thumbnail p-2"
                  src={item.url} // Replace with your image URL
                  alt={item.officer_name}
                  //width={200} // Define the width of the image
                />
                <Card.Body className="text-left"> {/* Add text-left class */}
                  <strong>{item.officer_name}</strong>
                  <br />
                  {item.officer_email}
                  <br />
                  {item.officer_position}
                </Card.Body>
              </Card>
            </Link>
          </Col>
        )
      ))}
    </Row>
    </>
)
}
