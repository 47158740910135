import React, { useEffect, useState } from 'react'
import axios from '../../libs/axios'
import { useParams } from 'react-router-dom'
import OfficerList from './components/OfficerList'
import BreadCrumb from './components/BreadCrumb'
import ErrorMEssage from './components/ErrorMessage'
import OfficerShow from './components/OfficerShow'

const DirectoryOfficerShow = () => {
    
    const { state } = useParams()
    const { department } = useParams()
    const { section } = useParams()
    const { directoryId } = useParams()

    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState([])
    const [error, setError] = useState(null)

    /**
     * Load Directory 
     * by state
     */
    useEffect(() => {
        const fetchData = async () => {
            setError(null)
            setData([])
            if(state !== "" && department !== "" && section !== "" ){
                try {
                    setIsloading(true)
                    const response = await axios.get(
                        // get all departments by given :state
                        `${process.env.REACT_APP_BACKEND_URL}/portal/directories/${directoryId}/show`
                    )
                    console.log(response.data);
                    setData(response.data.officer)    
                } catch (error) {
                    setError(error.response.data)
                    //console.log(error.response.data)
                }
                setIsloading(false)
            }
        };
        fetchData() // fetch data from backend
    }, [directoryId])

    let breadcrumb = {
        "Utama" : "/",
        "Direktori" : "/directories",
        [data?.state]: `/directories/${data.state_slug}`,
        [data?.department]: `/directories/${data.state_slug}/${data.department_slug}`,
        [data?.section]: `/directories/${data.state_slug}/${data.department_slug}/${data.section_slug}`,
        [data?.officer_name]: null
    }
  
    return (
        <>
            <BreadCrumb items={breadcrumb} />
         
            { isLoading ? '...loading' : 
                <>
                { data &&
                    <>     
                    <h1>{data?.section}</h1>   
                    <hr />
                    <OfficerShow item={data} />
                    </>
                }    
                </>
            }
            { error &&
                <ErrorMEssage message={error.message} />
            }
        </>
    )
} 
export default DirectoryOfficerShow