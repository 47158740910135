import { Row , Col , Image , Card} from 'react-bootstrap'
export default function OfficerShow ({item}) {
    return(
        <>
        <Row>
            <Col className='col-3'>
                <Image
                    className="thumbnail p-2 img-fluid"
                    src={item.url} // Replace with your image URL
                    alt={item.officer_name}
                    //width={200} // Define the width of the image
                    />
                </Col>
            <Col className='col-4 p-2'>
                <h3>Maklumat Pegawai</h3>
                <dl>
                    <dt>Nama:</dt>
                    <dd> {item.officer_name}</dd>

                    <dt>Jawatan:</dt>
                    <dd> {item.officer_position}</dd>

                    <dt>Email:</dt>
                    <dd> {item.officer_email}</dd>

                    <dt>Telefon:</dt>
                    <dd>{item.officer_phone}</dd>

                    <dt>Faks:</dt>
                    <dd>{item.officer_fax}</dd>
                </dl>
                       
            </Col>

            <Col className='col-4 p-2'>
            <h3>Maklumat Jabatan</h3>
                <dl>
                    <dt>Jabatan:</dt>
                    <dd> {item.department}</dd>

                    <dt>Seksyen:</dt>
                    <dd> {item.section}</dd>

                    <dt>Unit:</dt>
                    <dd> {item.unit}</dd>

                    <dt>Alamat:</dt>
                    <dd>{item.address}</dd>
                </dl>   
            </Col>
           
        </Row>
        </>
    )
}