import React, { useState, useEffect } from 'react';
import axios from '../../libs/axios'
import { useParams,Link } from 'react-router-dom'
import BreadCrumb from '../Directory/components/BreadCrumb';
import { Table } from 'react-bootstrap';
import DepartmentSearch from './Search';

const SearchResult = () => {

    const [data,setData] = useState([])
    const { query } = useParams()

    useEffect( () => {
        if (!query) {
            // Query is empty, handle it appropriately
            return;
        }

        axios(`${process.env.REACT_APP_BACKEND_URL}/departments/search/${query}`)
        .then(
            response => {
                //console.log(response)
                setData(response.data)
            }
        )
        .catch( error => {
            console.warn(error)
        })
    },[query])

    console.log(query)
    
    const breadcrumb = [
        { text: "Utama", link: "/" },
        { text: "Direktori", link: "/departments" },
        { text: "Carian", link: null }
      ];


        return (
            <div>
                <BreadCrumb items={breadcrumb} />
                <DepartmentSearch />

                {data &&
                    <>
                    <h3>Senarai Pegawai</h3>    
	            <div className="table-responsive">
                    
		    <Table className="table responsive-table table-striped">
                        <thead>
                            <th>NO.</th>
                            <th>GAMBAR</th>
                            <th>NAMA PEGAWAI</th>
                            <th>UNIT</th>
                            <th>JAWATAN</th>
                            <th>EMEL</th>
                            <th>NO. TELEFON</th>
                        </thead>
                        <tbody>
                        {data?.map((item, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>
                                    <Link to={`/departments/${item.state_slug}/${item.department_slug}/${item.unit_slug}/${item.id}/${item.officer_name_slug}`}>
                                        <img style={{'width' : '100px'}} className='img-thumbnail rounded img-responsive' src={item.officer_url} />
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/departments/${item.state_slug}/${item.department_slug}/${item.unit_slug}/${item.id}/${item.officer_name_slug}`}>
                                       {item.officer_name}
                                    </Link>
                                </td>
                                <td>{item.name}</td>
                                <td>{item.officer_position}</td>
                                <td>{item.officer_email}</td>
                                <td>{item.officer_phone}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
		    </div>
                    </>
                }

            </div>
        );

};

export default SearchResult;
