import React from 'react';
import { Card,Col,Row,Image,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const TableList = ({data}) => {
    //console.log(data)
    return (
        <div>
       
        <table className="table table-bordered" id="table-id">
            <thead>
                <tr>
                    <th>Tajuk</th>
                </tr>
            </thead>
            <tbody>

            {data.pages?.map((item, index) => (
            <>
      
                <tr>
                    <td>
        
                        <Link style={{ textDecoration: 'none', color: '#333333' }} to={`/contents/${item.id}/${item.slug}`}>
                            {item.title?.toUpperCase()}
                        </Link>
                    </td>
                </tr>
            </>
            ))}

            </tbody>
        </table>

  
        </div>
    );
};

export default TableList;