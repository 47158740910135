import React, { useState, useEffect } from 'react';
import axios from '../../libs/axios'
import { Form, Link, useNavigate } from 'react-router-dom'
import BreadCrumb from '../Directory/components/BreadCrumb';
import DepartmentSearch from './Search'
import './style.css'

const Departments = () => {

    const [data,setData] = useState([])

    useEffect( () => {
        axios(`${process.env.REACT_APP_BACKEND_URL}/departments`)
        .then(
            response => {
                //console.log(response)
                const data = {
                    states : response.data
                }
                setData(data)
            }
        )
        .catch()
    },[])
    
    
    //console.log(data)
    const breadcrumb = [
        { text: "Utama", link: "/" },
        { text: "Direktori",link:null },
      ];

    return (
        <div>
            <BreadCrumb items={breadcrumb} />

            <DepartmentSearch />

          
            <ul  className="list-group" >
            {data?.states?.map((item, index) => (
                <li className="list-group-item  border-0" key={index}>
                    <Link id="linkdirektori" to={`/departments/${item.state_slug}`}><h2>{item.state}</h2></Link>
                </li>
            ))}
            </ul>
        </div>
    );
};

export default Departments;