import { Outlet} from "react-router-dom"
import Menu1 from "./components/menu1";
import Menu2 from "./components/menu2";
import Footer from "./components/footer";

const ContentLayout = () => {

  return (
    <>
      <Menu1 />
      <Menu2 />
      <div className="container-fluid col-10 p-5" >
        <Outlet />
      </div>
      <Footer />
    </>
  )
};

export default ContentLayout;