import { Link } from 'react-router-dom'

// export default function BreadCrumb ({items}) {

//     return (
//         <ul className='breadcrumb'>
//         {Object.entries(items).map(([text, link], index) => (
//             text !== 'undefined' && text !== 'null' && ( // Check if text is not 'undefined'
//             <li key={index}>
//                 {link ? <Link to={link}>{text}</Link> : text}
//             </li>
//             )
//         ))}
//         </ul>
//     )
// }
export default function BreadCrumb({ items }) {
    // Check if items is an array
    if (!Array.isArray(items)) {
      console.error('Invalid items. Expected an array.');
      return null; // or return an error message, throw an error, or handle it as appropriate
    }
  
    return (
      <ul className='breadcrumb'>
        {items.map((item, index) => (
          item && item.text !== 'undefined' && item.text !== 'null' && (
            <li key={index}>
              {item.link ? <Link to={item.link}>{item.text}</Link> : item.text}
            </li>
          )
        ))}
      </ul>
    );
  }
  
  
