import React, { useEffect, useState } from 'react'
import axios from '../../libs/axios'
import { Link,useParams } from 'react-router-dom'

import Default from './components/Default'
import Tender from './components/Tender'
import RateCard from './components/RateCard'
import TableList from './components/TableList'

const ContentIndex = () => {
    
    const { hashtag } = useParams()
    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState(null)

    /**
     * Load contents from PageCategory
     * by #pageCategoryId
     */
    useEffect(() => {
        const fetchData = async () => {
  
            try {
                setIsloading(true)
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/portal/page-category/${hashtag}/hashtag`
                )

                console.log(hashtag)
                setData(response.data)
                setIsloading(false)
            } catch (error) {
                console.log(error.response.data)
            }
        };

        fetchData()

    }, [hashtag])

    return (
        <>
            <ul className="breadcrumb">
                <li><Link to="/">Utama</Link></li>
                <li>{pascalCaseToNormalString(data?.category)}</li>
            </ul>

            { isLoading ? '...loading' : 
            <>
                
                {/* {data && hashtag === 'tender' ? (
                    <Tender data={data} />
                ) : (
                    data && <Default data={data} />
                )} */}

            <div>
        
            {data &&
                <>
                {(() => {
                    switch (hashtag) 
                    {
                        case 'soalan-lazim': return <TableList data={data} />
                        case 'siaran-media': return <TableList data={data} />
                        case 'galeri': return <TableList data={data} />
                       
                        case 'borang-ict': return <TableList data={data} />
                        case 'sistem-eperolehan': return <TableList data={data} />
                        case 'bahagian': return <TableList data={data} />
                        case 'maklumat-rancangan-tv-epromo-rtm': return <TableList data={data} />
                        case 'bahagian': return <TableList data={data} />
                        case 'rate-card': return <RateCard data={data} />
                        case 'tender-sebutharga': return <Tender data={data} />
                        default : return <Default data={data} />
                    }
                })()}
                </>
            }
            </div>

            </>  
            }
        </>
    )
} 

export default ContentIndex

function pascalCaseToNormalString  (pascalCaseString) {
    // Split the string by uppercase letters
    const words = pascalCaseString?.split(/(?=[A-Z])/);
  
    // Join the words with spaces and convert to lowercase
    const normalString = words?.join(' ');
  
    return normalString;
  };
