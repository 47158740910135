import React, {  useState } from 'react'
import { Row,Card,Col, Image, Modal } from 'react-bootstrap';

const Gallery = ({contents,className}) => {

    const [data, setData] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    
    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setShowModal(true);
    }


    return (

        <Card className={className}>
  
        {/* <Card.Header className='mb-3 p-3'>Galeri Gambar</Card.Header> */}
        <Row className='m-3'>

            { contents.map((item, index) => (
        
                <Col className="mb-3" xs={6} md={4}>
          
                        
                  
                        <Image
                            style={{ 'cursor':'pointer'}}
                            className="img-thumbnail p-3 "
                            src={item?.url}
                            alt={`Image ${index}`}
                            onClick={() => handleImageClick(index)} // Open the modal on click
                        />
                       
               
                </Col>    
       
            ))}

            <Modal show={showModal} onHide={() => setShowModal(false)}  size="xl" centered>
                <Modal.Header> {/* Add closeButton prop */}
                    <Modal.Title>Image Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedImageIndex !== null && (
                    <Image
                        className="img-fluid"
                        src={contents[selectedImageIndex].url}
                        alt={`Image ${selectedImageIndex}`}
                    />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>
        </Row>
              
        </Card>
    );
};

export default Gallery;
