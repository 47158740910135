import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from "../../libs/axios"

const LoadFooter = () => {

    const hashtag ='footer'
    const [data,setData] = useState(null)

    /**
     * Load contents from PageCategory
     * by #hashtag
     */
    useEffect(() => {
        const fetchData = async () => {
  
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/portal/${hashtag}`
                )
                setData(response.data.pageCategories)
                //console.log(response.data.pageCategories)
            } catch (error) {
                console.log(error.response.data)
            }
        };

        fetchData()

    }, [])

    const listItems = data?.map((item, index) => (

        <li key={index}>
            <NavLink 
              to={`/contents/${item.id}/${item.slug}`} 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
                {item.title}
            </NavLink>
        </li>
      ));
      
    return (
        <>
            <ul className="footer_ul_amrc ">
                {listItems}
            </ul>
        </>
    )
}
export default LoadFooter