import { Outlet } from "react-router-dom"
import Menu1 from "./components/menu1";
import Menu2 from "./components/menu2";
import Footer from "./components/footer";

const HomeLayout = () => {

  return (
    <>
    <Menu1 />
    <Menu2 />
    <Outlet />
    <Footer />
    </>
  )
};

export default HomeLayout;