import React, { useState, useEffect } from 'react';
import axios from '../../libs/axios'
import { Link, useParams } from 'react-router-dom'
import BreadCrumb from '../Directory/components/BreadCrumb';
import { Table } from 'react-bootstrap';
import DepartmentSearch from './Search';

const DepartmentOfficer = () => {

    const [data,setData] = useState([])
    const { state } = useParams()
    const { department } = useParams()
    const { section } = useParams()
    const { id } = useParams()
    const { name } = useParams()

    useEffect( () => {
        axios(`${process.env.REACT_APP_BACKEND_URL}/departments/${state}/${department}/${section}/${id}/${name}`)
        .then(
            response => {
                //console.log(response)
                setData(response.data)
            }
        )
        .catch()
    },[name])

    //console.log(data?.staff?.officer_name)

    //console.log(data)
    
    const breadcrumb = [
        { text: "Utama", link: "/" },
        { text: "Direktori", link: "/departments" },
        { text: data?.state, link: `/departments/${state}` },
        { text: data?.department, link: `/departments/${state}/${department}` },
        { text: data?.section, link: `/departments/${state}/${department}/${section}` },
        { text: data?.staff?.officer_name, link: null }
      ];

    if(data){ 
        return (
            <div>
               
                <BreadCrumb items={breadcrumb} />
                <DepartmentSearch />

                {data?.staff &&
                    <>
                   
                    <h3 className='text-center col-md-6'>{data?.staff?.officer_name}</h3> 
                    {/* <div className="container-fluid" >
                    <img id="gambardirektori" src={data?.staff?.url} />
                    <div style={{ marginTop: '4rem' }}>
  <h5 style={{ marginTop: '-1.5rem' }}>Jawatan</h5>
  <p style={{ marginTop: '-1.5rem' }}>Ketua Pengarah Penyiaran</p>

  <h5 style={{ marginTop: '-1.5rem' }}>Alamat</h5>
  <p style={{ marginTop: '-1.5rem' }}>
    PEJABAT KETUA PENGARAH PENYIARAN<br />
    Jabatan Penyiaran Malaysia,<br />
    Tingkat 6, Wisma TV,<br />
    Angkasapuri Kota Media,<br />
    50614 Kuala Lumpur.
  </p>

  <h5 style={{ marginTop: '-1.5rem' }}>Emel</h5>
  <a id="linklistpnamapegawai" href="mailto:suhaimisulaiman@rtm.gov.my">
    <p style={{ marginTop: '-1.5rem' }}>suhaimisulaiman@rtm.gov.my</p>
  </a>

  <h5 style={{ marginTop: '-1.5rem' }}>No. Telefon</h5>
  <p style={{ marginTop: '-1.5rem' }}>03-2288 7303</p>

  <h5 style={{ marginTop: '-1.5rem' }}>No. Faks</h5>
  <p style={{ marginTop: '-1.5rem' }}>03-2284 7591</p>
</div>
</div> */}

                    {/* <Table className='col-md-6'>

                        <tr>
                            <td colSpan={2}><img id="gambardirektori"  src={data?.staff?.url} /></td>
                        </tr>
                        <tr>
                            <th>NAMA</th>
                            <td>{data?.staff?.officer_name}</td>
                        </tr>

                        <tr>
                            <th>ALAMAT</th>
                            <td>{data?.staff?.address}</td>
                        </tr>

                        <tr>
                            <th>JAWATAN</th>
                            <td>{data?.staff?.officer_position}</td>
                        </tr>

                        <tr>
                            <th>EMEL</th>
                            <td>{data?.staff?.officer_email}</td>
                        </tr>

                        <tr>
                            <th>NO. TELEFON</th>
                            <td>{data?.staff?.officer_phone}</td>
                        </tr>

                    </Table> */}
                <div class="container" id="containerdirektori">
                    <div className='row'>
                        <h3>{data?.staff?.officer_namel}</h3>
                        <hr />
                        <div className="container" id="containerdirektori">
                            <div className="row">
                                <div className="col-lg-4 justify-content-center">
                               
                                    <img className="img-fluid" id="gambardirektoripegawai" src={data?.staff?.officer_url} alt="Staff" />
                                </div>
                                <div className="col-lg-8" id="namecardkaler">
                                    <div className="row" style={{ marginTop: '1.5rem' }}>
                                        <div className="col-lg-5">
                                            <h5 id="kalertulisan">JAWATAN</h5>
                                            <p id="kalertulisan">{data?.staff?.officer_position}</p>
                                            <br />
                                            <h5 id="kalertulisan">ALAMAT</h5>
                                            <p id="kalertulisan">
                                                {data?.staff?.address}
                                            </p>
                                            <br />
                                        </div>

                                        <div className="col-lg-7">
                                            <h5 id="kalertulisan">EMEL</h5>
                                            <a href={`mailto:${data?.staff?.officer_email}`}>
                                                <p id="kalertulisan">{data?.staff?.officer_email}</p>
                                            </a>

                                            <br />
                                            <h5 id="kalertulisan">NO. TELEFON</h5>
                                            <p id="kalertulisan">{data?.staff?.officer_phone}</p>

                                            <br />
                                            <h5 id="kalertulisan">MEDIA SOSIAL</h5>
                                            {data?.staff?.officer_facebook && 
                                                <a target="_blank" href={data?.staff?.officer_facebook}><img src="/img/facebook.svg" /></a>
                                            }
                                            {' '}
                                            {data?.staff?.officer_instagram && 
                                            <a target="_blank" href={data?.staff?.officer_instagram}><img src="/img/instagram.svg" /></a>
                                            }
                                            {' '}
                                            {data?.staff?.officer_twitter && 
                                            <a target="_blank" href={data?.staff?.officer_twitter}><img src="/img/x.png" /></a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                }

            </div>
        );
    }


};

export default DepartmentOfficer;