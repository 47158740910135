import { NavLink } from 'react-router-dom'
const Menu2 = () => {

    return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light shadow-sm" style={{ 'backgroundColor':'white' }} id="navbardibawah">
  
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarbawah" aria-controls="navbarbawah" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarbawah">
        <ul className="navbar-nav mx-auto">
    
        <li className="nav-item">
            <NavLink 
                to={`/`} 
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
            UTAMA
            </NavLink>
        </li>
    
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            TV
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{ 'marginTop':'-10px' }} >
                <a className="dropdown-item" target="_blank" href="https://rtmklik.rtm.gov.my/live/tv1">TV1</a>
                <a className="dropdown-item" target="_blank" href="https://rtmklik.rtm.gov.my/live/tv2">TV2</a>
                <a class="dropdown-item" target="_blank" href="https://rtmklik.rtm.gov.my/live/okey">TV Okey</a>
                <a class="dropdown-item" target="_blank" href="https://rtmklik.rtm.gov.my/live/beritartm">Berita RTM</a>
                <a class="dropdown-item" target="_blank" href="https://rtmklik.rtm.gov.my/live/sukanrtm">Sukan RTM</a>
                <a class="dropdown-item" target="_blank" href="https://rtmklik.rtm.gov.my/live/tv6">TV6</a>
               
            </div>
        </li>
        
        <li className="nav-item">
            <a className="nav-link" target="_blank"  href="https://radio.rtm.gov.my">RADIO</a>
        </li>
        
        
        
        <li className="nav-item">
            <NavLink 
                to={`/contents/pencapaian`} 
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
            PENCAPAIAN
            </NavLink>
        </li>
            
        <li className="nav-item">
            <NavLink 
                to={`/contents/aktiviti`} 
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
            AKTIVITI
            </NavLink>
        </li>
        
        <li className="nav-item">
            <NavLink 
                to={`/contents/rate-card`} 
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
            RATECARD RTM
            </NavLink>
        </li>        
        
        <li className="nav-item">
            <a className="nav-link" target="_blank"  href="/images/foto_galeri/index.html">GALERI</a>
        </li>
        
        
        <li className="nav-item">
            <NavLink 
                to={`/departments`} 
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
            DIREKTORI
            </NavLink>
        </li>
        
        
        
        {/* <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            DIREKTORI
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{ 'marginTop':'-10px' }} >
                <NavLink 
                    to={`/directories/angkasapuri`} 
                    className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')}
                >
                ANGKASAPURI
                </NavLink>
                <NavLink 
                    to={`/directories/states`} 
                    className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')}
                >
                DIREKTORI NEGERI
                </NavLink>
            </div>
        </li> */}
        


        </ul>

    </div>


    </nav>
    </>
    )

}
export default Menu2;