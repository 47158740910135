import React from 'react';
import { Card } from 'react-bootstrap';

const Document = ({contents,className}) => {
        //console.log(items)
    const containerStyle = {
        position: 'relative',
        width: '100%', // Adjust the container width as needed
        height: '90vh', // Adjust the container height as needed
        margin: 'auto', // Center the container horizontally
        overflow: 'hidden',
        };
    
    const iframeStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        border: 'none',
    };
        
    return (
        <div>

            { contents.map((item, index) => (
            <Card key={index} className={className}>
                {/* <Card.Header>
                    {item.filename}
                </Card.Header> */}
                <Card.Body>
                    <div style={containerStyle}>
                        <iframe
                            title={item.filename}
                            src={item.url}
                            style={iframeStyle}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </Card.Body>
            </Card>
            ))}

        </div>
    );
};

export default Document;