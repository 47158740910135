import { Link } from 'react-router-dom'
import { Row,Col,Card,Image } from 'react-bootstrap'


export default function UnitList ({items}) {

    //console.log(items)
    return (
    <>
    <Row xs={1} md={2} lg={3}>
    {items.map((item, index) => (
        <Col key={index}>
            <Link to={`/directories/${item.state_slug}/${item.department_slug}/${item.unit_slug}`}>
                <Card className='bg-light p-3 mb-3'>
                <h4>{item.name}</h4>
                </Card>
            </Link>
        </Col>
    ))}
    </Row>
    </>
    )
}
