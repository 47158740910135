import Axios from 'axios'

const axios = Axios.create({
    baseURL:  (process.env.REACT_APP_BACKEND_URL),
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
})

// intercept every request
axios.interceptors.request.use(
    (config) => {
        config.headers['Accept'] = 'application/json' // return reesponse in JSON
        return config; // return back config()
    },
    (error) => {
      return Promise.reject(error);
    }
  )
export default axios
