import React, { useEffect, useState } from 'react'
import axios from '../../libs/axios'
import { Link,useParams } from 'react-router-dom'

// components
import Content from './components/Content';
import Poster from './components/Poster';
import HTML from './components/HTML';
import Document from './components/Document';
import Gallery from './components/Gallery';
import Video from './components/Video';

const Show = () => {
    
    const { pageCategoryId } = useParams()
    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState(null)

    /**
     * Load contents from PageCategory
     * by #pageCategoryId
     */
    useEffect(() => {
        const fetchData = async () => {
  
            try {
                setIsloading(true)
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/portal/${pageCategoryId}/page-category`
                )
                setData(response.data.pageCategory)
              
                console.log(response.data.pageCategory)
                setIsloading(false)
            } catch (error) {
                console.log(error.response.data)
            }
        };

        fetchData()

    }, [pageCategoryId])

    // general bootstrap class
    // for each Component
    const className = 'mb-3 border-0'

    return (
        <>
            <ul class="breadcrumb">
                    <li><Link to="/">Utama</Link></li>
                    <li><Link to={`/contents/${data?.hashtag}`}>{data?.category}</Link></li>
                    <li>{data?.title}</li>
            </ul>

            { isLoading ? '...loading' : 
                <>
                {data &&
                    <>
                        {/* { data.page_metadata && 
                            <Poster 
                                image={data.page_metadata.url}
                        
                            />
                        } */}

                        { data.contents && 
                            <Content 
                                title={data.title}
                                contents={data.contents}
                                className={className}
                            />
                        }

                        {Array.isArray(data.page_contents) && data.page_contents.length > 0 && (
                            
                            <HTML
                                contents={data.page_contents}
                                className={className}
                            />
                        )}

                        {Array.isArray(data.page_videos) && data.page_videos.length > 0 && (
                            
                            <Video
                                contents={data.page_videos}
                                className={className}
                            />
                        )}

                        
                        {Array.isArray(data.page_documents) && data.page_documents.length > 0 && (
                            
                            <Document
                                contents={data.page_documents}
                                className={className}
                            />
                        )}

                        {Array.isArray(data.page_images) && data.page_images.length > 0 && (
                            
                            <Gallery
                                contents={data.page_images}
                                className={className}
                            />
                        )}
                    </>
                } 
                </>  
            }
        </>
    )
} 

export default Show