import React, { useEffect, useState } from 'react'
import axios from '../../libs/axios'
import { useParams } from 'react-router-dom'
import OfficerList from './components/OfficerList'
import SectionList from './components/SectionList'
import UnitList from './components/UnitList'
import ErrorMEssage from './components/ErrorMessage'
import BreadCrumb from './components/BreadCrumb'


const DirectoryDepartments = () => {
    
    const { state } = useParams()
    const { department } = useParams()
    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState([])
    const [error, setError] = useState(null)

    /**
     * Load Directory 
     * by state
     */
    useEffect(() => {
        const fetchData = async () => {
            setError(null)
            setData([])
            if(state !== ""){
                try {
                    setIsloading(true)
                    const response = await axios.get(
                        // get all departments by given :state
                        `${process.env.REACT_APP_BACKEND_URL}/portal/directories/${state}/${department}`
                    )
                    //console.log(response.data);
                    setData(response.data)    
                } catch (error) {
                    setError(error.response.data)
                    //console.log(error.response.data)
                }
                setIsloading(false)
            }
        };
        fetchData() // fetch data from backend
    }, [state,department])

    let breadcrumb = {
        "Utama" : "/",
        "Direktori" : "/directories",
        [data?.state]: `/directories/${state}`,
        [data?.department]: null
    }
  
    return (
        <>
            <BreadCrumb items={breadcrumb} />
         
            { isLoading ? '...loading' : 
                <>
                { data &&
                    <>     
                    <h1>{data?.department}</h1>   
                    
                    {data?.officers?.length > 0 && (
                    <>   
                        <hr />
                        <OfficerList items={data.officers} />
                    </>
                    )} 

                    {data?.sections?.length > 0 && (
                        <>
                        <h1>Senarai Seksyen</h1>
                        <hr />
                        <SectionList items={data.sections} />
                        </>
                    )}

                    {data?.units?.length > 0 && (
                        <>
                        <h1>Senarai Unit</h1>
                        <hr />
                        <UnitList items={data.units} />
                        </>
                    )}
                    </>
                }    
                </>
            }
            { error &&
                <ErrorMEssage message={error.message} />
            }
        </>
    )
} 
export default DirectoryDepartments