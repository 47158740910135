import React from 'react';
import { Card,Col,Row,Image,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const Default = ({data}) => {
    //console.log(data)
    return (
        <div>
   
            {data.pages?.map((item, index) => (
            
            <Row className='mb-4'>
                <Col>
                {item.page_metadata &&
                <>
                    <Image className="img-fluid rounded" src={item.page_metadata?.url} />
                </>
                }

                </Col>
                <Col md={10}>
                  
                    <Row className='mb-2'>
                        <Link to={`/contents/${item.id}/${item.slug}`}>
                            <h5>{item.title}</h5>
                        </Link>
                    </Row>  
                   
                    <Row className='mb-2'>
                    {item.page_metadata &&
                        <>
                            {item.page_metadata?.description}
                        </>
                    }
                    </Row>
                  
                </Col>
            </Row>
                
               
            ))}
        
            
            
        </div>
    );
};

export default Default;