import React, { useState, useEffect } from 'react';
import axios from '../../libs/axios'
import { Link, useParams } from 'react-router-dom'
import BreadCrumb from '../Directory/components/BreadCrumb';
import { Table } from 'react-bootstrap';
import DepartmentSearch from './Search';

const DepartmentIndex = () => {

    const [data,setData] = useState([])
    const { state } = useParams()
    const { department } = useParams()

    useEffect( () => {
        axios(`${process.env.REACT_APP_BACKEND_URL}/departments/${state}/${department}`)
        .then(
            response => {
                //console.log(response)
                setData(response.data)
            }
        )
        .catch()
    },[state,department])


    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    //console.log(data)
    
    const breadcrumb = [
        { text: "Utama", link: "/" },
        { text: "Direktori", link: "/departments" },
        { text: data?.state, link: `/departments/${state}` },
        { text: data?.department, link: null }
      ];

    if(data){ 
        return (
            <div>
                <BreadCrumb items={breadcrumb} />
                <DepartmentSearch />
                
                <div>
                    <a id="linkdirektoridiv" href="">
                        <h4 style={{ marginTop: '2rem', backgroundColor: '#063970', color: 'white', padding: '1rem' }}>
                            {data?.department}
                        </h4>
                    </a>
                </div>


                {data?.heads &&
                    <>
   
                    {/* <h3 style={{ marginTop: '2rem', }}>HEAD</h3> */}
                    <ul className="list-group">
                    {data?.heads?.map((item, index) => (
                        <li className="list-group-item  border-0" key={index}>
                            <Link to={`/departments/${state}/${department}/${item.unit_slug}`}>{item.unit}</Link>
                        </li>
                    ))}
                    </ul>
                   
                 
                    </>
                }

                {data?.sections &&
                    <>
                    {/* <h3 style={{ marginTop: '2rem'}}>SEKSYEN</h3>   */}
                    <ul class="list-group">
                    {data?.sections?.map((item, index) => (
                        <li class="list-group-item  border-0" key={index}>
                            <Link to={`/departments/${state}/${department}/${item.section_slug}`}>{item.name}</Link>
                            <ul>
                            {item?.units?.map((unit, index) => (
                                <li key={index}>
                                    <Link to={`/departments/${state}/${department}/${unit.unit_slug}`}>{unit.name}</Link>
                                </li>
                            ))}
                            </ul>
                        </li>
                    ))}
                    </ul>
                  
                    </>
                }

                {data?.units?.length > 0  &&
                    <>
                    <h3>Unit</h3>    
                    
                    <ul>
                    {data?.units?.map((item, index) => (
                        <li key={index}>
                            <Link to={`/departments/${state}/${department}/${item.unit_slug}`}>{item.name}</Link>
                        </li>
                    ))}
                    </ul>
                    </>
                }


            </div>
        );
    }


};

export default DepartmentIndex;