import React from 'react';
import { Card,Col,Row,Image,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import HTML from '../../Show/components/HTML';

const RateCard = ({data}) => {
    //console.log(data)
    return (
        <div>
            
            {data.pages?.map((item, index) => (
                <>
                <h1>{item.title}</h1>
                <hr />       
                {Array.isArray(item.page_contents) && item.page_contents.length > 0 && (
                            
                    <HTML
                        contents={item.page_contents}
                        className='border-0'
                    />
                )}
                </>

                
            ))}
        </div>
    );
};

export default RateCard;