import React, { useEffect, useState } from 'react'
import axios from '../libs/axios'
import { Link,useParams } from 'react-router-dom'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';

const Content = () => {
    
    const { pageCategoryId } = useParams()
    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    /**
     * Load contents from PageCategory
     * by #pageCategoryId
     */
    useEffect(() => {
        const fetchData = async () => {
  
            try {
                setIsloading(true)
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/portal/${pageCategoryId}/page-category`
                )
                setData(response.data.pageCategory)
              
                console.log(response.data.pageCategory)
                setIsloading(false)
            } catch (error) {
                console.log(error.response.data)
            }
        };

        fetchData()

    }, [pageCategoryId])

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setShowModal(true);
    }

    const containerStyle = {
        position: 'relative',
        width: '100%', // Adjust the container width as needed
        height: '80vh', // Adjust the container height as needed
        margin: 'auto', // Center the container horizontally
        overflow: 'hidden',
      };
    
      const iframeStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        border: 'none',
      };
      

    return (
        <>
            <ul class="breadcrumb">
                    <li><Link to="/">Utama</Link></li>
                    <li><Link to={`/contents/${data?.hashtag}`}>{data?.category}</Link></li>
                    <li>{data?.title}</li>
            </ul>

            { isLoading ? '...loading' : 
                <>
                {data &&
                    <>
                        <h2>{data.title}</h2>
                        <p>{data.contents}</p>

                        {data.page_images.length === 1 ? (
                            <img className="img-fluid" src={data.page_images[0].url} alt="Image" />
                        ) 
                        
                        : 
                            <Row>
                                {data.page_images.map((image, index) => (
                                <Col className="mb-3" key={index} xs={6} md={4}>
                                    {/* <img className="img-thumbnail" src={image.url} alt={`Image ${index}`} /> */}
                                    <img
                                        style={{ 'cursor':'pointer'}}
                                        className="img-thumbnail"
                                        src={image?.url}
                                        alt={`Image ${index}`}
                                        onClick={() => handleImageClick(index)} // Open the modal on click
                                    />
                                </Col>
                                ))}
                            </Row>
                        }

                        <Modal show={showModal} onHide={() => setShowModal(false)}  size="xl" centered>
                            <Modal.Header> {/* Add closeButton prop */}
                                <Modal.Title>Image Viewer</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {selectedImageIndex !== null && (
                                <img
                                    className="img-fluid"
                                    src={data.page_images[selectedImageIndex].url}
                                    alt={`Image ${selectedImageIndex}`}
                                />
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>


                    </>
                } 
                </>  
            }
        </>
    )
} 

export default Content