import React, { useEffect, useState } from 'react'
import axios from '../../libs/axios'
import { Link } from 'react-router-dom'

import ErrorMEssage from './components/ErrorMessage';
import BreadCrumb from './components/BreadCrumb';
import { Card, Col, Row } from 'react-bootstrap';


const DirectoryIndex = () => {
    
    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState([])
    const [error, setError] = useState(null)

    /**
     * Load Directory 
     * by state
     */
    useEffect(() => {
        const fetchData = async () => {
            setError(null)
            setData([])
           
                try {
                    setIsloading(true)
                    const response = await axios.get(
                        // get all departments by given :state
                        `${process.env.REACT_APP_BACKEND_URL}/portal/directories/index`
                    )

                    //console.log(response.data);
                    setData(response.data)
                    
                } catch (error) {
                    setError(error.response.data)
                    console.log(error.response.data)
                }
                setIsloading(false)
   
        };
        fetchData()
    }, [])
  
    let breadcrumb = {
        "Utama" : "/",
        "Direktori" : "/directories"
    }

    return (
        <>
        <BreadCrumb items={breadcrumb} />
         
            { isLoading ? '...loading' : 
              
                <>
                {data && (
                <>
                    <h1>Direktori</h1>
                    <hr />
                    <Row xs={1} md={2} lg={3}>
                    {data.states?.map((item, index) => (
                        <Col key={index}>
                            <Link style={{ 'textDecoration':'none'}} to={`/directories/${item.name_slug}`}>
                                <Card className='p-3 mb-3 bg-light'>
                                
                                    <h3>{item.name}</h3>
                                    <hr />
                                    <ul className="list-unstyled">
                                    {item.departments.map((department, idx) => (
                                        <li className="text-muted" key={idx}>
                                        - {department.name}
                                        </li>
                                    ))}
                                    </ul>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                    </Row>
                </>
                )}

                </>
            }
            { error &&
                <>
                <ErrorMEssage message={error.message} />
                </>
            }
        </>
    )
} 

export default DirectoryIndex