import React, { useEffect, useState } from 'react'
import axios from '../../libs/axios'
import { Link,useParams } from 'react-router-dom'
import { Row,Col,Card,Image } from 'react-bootstrap'

import BreadCrumb from './components/BreadCrumb'
import ErrorMessage from './components/ErrorMessage'

const DirectoryStates = () => {
    
    const { state } = useParams()
    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState([])
    const [error, setError] = useState(null)


    /**
     * Load Directory 
     * by state
     */
    useEffect(() => {
        const fetchData = async () => {
            setError(null)
            setData([])
            if(state !== ""){
                try {
                    setIsloading(true)
                    const response = await axios.get(
                        // get all departments by given :state
                        `${process.env.REACT_APP_BACKEND_URL}/portal/directories/${state}`
                    )

                    console.log(response.data);
                    setData(response.data)
                    
                    
                } catch (error) {
                    setError(error.response.data)
                    console.log(error.response.data)
                }
                setIsloading(false)
                
            }
        };

        fetchData()

    }, [state])
  
    let breadcrumb = {
        "Utama" : "/",
        "Direktori" : "/directories",
        [data?.state] : null,
  
    }

    return (
        <>
            <BreadCrumb items={breadcrumb} />
         
            { isLoading ? '...loading' : 
              
                <>
                
                { data &&
                    <>     
                    <h1>{data?.state}</h1>  
                    <hr />  
                    {/* <ul style={{ 'listStyleType' : 'none'}}>
                        {data.departments?.map((item, index) => (
                            <li key={index}>
                                <Card className="bg-light p-3 mb-3">
                                <Link to={`/directories/${state}/${item.name_slug}`}>
                                    <h3>{item.name}</h3>
                                </Link>
                                
                         
                                {item.sections.length > 0 && (
                                <>
                                <h5 className='text-muted'>Seksyen</h5>
                                <ol>
                                {item.sections?.map((section, idx) => (
                                    section.name !== null && (
                                    
                                        <li key={idx} className='text-muted'>{section.name}</li>
                                    
                                    )
                                ))}
                                </ol>
                                </>
                                )}

                                {item.units.length > 0 && (
                                    <>
                                    <h5>Unit</h5>
                                    <ol>
                                    {item.units?.map((unit, idx) => (
                                        unit.name !== null && (
                                        
                                            <li key={idx} className='text-muted'>{unit.name}</li>
                                        
                                        )
                                    ))}
                                    </ol>
                                    </>
                                )}


                             
                           
                             </Card>
                            </li>
                        ))}
                    </ul> */}
                    <Row xs={1} md={2} lg={3}>
                    {data.departments?.map((item, index) => (
                        <Col key={index}>
                            <Link style={{ 'textDecoration':'none'}} to={`/directories/${state}/${item.name_slug}`}>
                                <Card className="bg-light p-3 mb-3">
                            
                                    <h3>{item.name}</h3>
                                

                                    {item.sections.length > 0 && (
                                    <>
                                        <hr />
                                        <ul className='text-muted'>
                                        {item.sections?.map((section, idx) => (
                                            section.name !== null && (
                                            <li className="list-unstyled" key={idx}>- {section.name}</li>
                                            )
                                        ))}
                                        </ul>
                                    </>
                                    )}

                                    {item.units.length > 0 && (
                                    <>
                                        <hr />
                                        <ul className='text-muted'>
                                        {item.units?.map((unit, idx) => (
                                            unit.name !== null && (
                                            <li className="list-unstyled" key={idx}>- {unit.name}</li>
                                            )
                                        ))}
                                        </ul>
                                    </>
                                    )}
                            </Card>
                        </Link>
                        </Col>
                    ))}
                    </Row>
                    </>
                }    
                </>
            }
            { error &&
                <ErrorMessage message={error.message} />
            }
        </>
    )
} 

export default DirectoryStates