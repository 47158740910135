import { Row,Col,Card, Image} from 'react-bootstrap'

export default function ErrorMEssage ({message}) {

    return (
        <>
        <Row>
        <div className='alert alert-danger'>
            <h2>Error</h2>
            {message}
        </div>
        </Row>
        </>
    )
}
