import React from 'react';
import { Row,Card,Col, Image, Modal } from 'react-bootstrap';
import YouTube from 'react-youtube';

const Video = ({contents,className}) => {
    const opts = {
        // height: '720px',
        // width: '1280px',
      };
    return (
        <div>
             <Card className={className}>
                
                {/* <Card.Header className='mb-3 p-3'>Galeri Video</Card.Header> */}
                <Row className='m-3  mx-auto'>

                    { contents.map((item, index) => (

                        <Col index={index} className="mb-3 ">
                            
                            <YouTube 
                                opts={opts}
                                videoId={item.youtube} 
                            />
                            {item.title}
                        </Col>    
            
                    ))}
      </Row>
      </Card>
        </div>
    );
};

export default Video;