import React, { useState, useEffect } from 'react';
import axios from '../../libs/axios'
import { Link, useParams } from 'react-router-dom'
import BreadCrumb from '../Directory/components/BreadCrumb';
import { Table } from 'react-bootstrap';
import DepartmentSearch from './Search';

const DepartmentSection = () => {

    const [data,setData] = useState([])
    const { state } = useParams()
    const { department } = useParams()
    const { section } = useParams()

    useEffect( () => {
        axios(`${process.env.REACT_APP_BACKEND_URL}/departments/${state}/${department}/${section}`)
        .then(
            response => {
                //console.log(response)
                setData(response.data)
            }
        )
        .catch()
    },[state,department,section])



    //console.log(data)
    
    const breadcrumb = [
        { text: "Utama", link: "/" },
        { text: "Direktori", link: "/departments" },
        { text: data?.state, link: `/departments/${state}` },
        { text: data?.department, link: `/departments/${state}/${department}` },
        // { text: data?.unit, link: `/departments/${state}/${department}/${type}` },
        { text: data?.section, link: null }
      ];

    if(data){ 
        return (
            <div>
                <BreadCrumb items={breadcrumb} />
                <DepartmentSearch />

                
                <div>
                    <a id="linkdirektoridiv" href="">
                        <h3 style={{ marginTop: '2rem', backgroundColor: '#063970', color: 'white', padding: '1rem' }}>
                        {data?.section}
                        </h3>
                    </a>
                </div>


                {data?.staffs &&
                    <>
              	<div className="table-responsive">   
                    <Table className="table responsive-table table-striped" id="table-id">
                        <thead>
                            <tr style={{ backgroundColor: '#063970' }}>
                                <th style={{ color: 'white' }}>NO.</th>
                                <th style={{ color: 'white' }}>GAMBAR</th>
                                <th style={{ color: 'white' }}>NAMA PEGAWAI</th>
                                <th style={{ color: 'white' }}>JAWATAN</th>
                                <th style={{ color: 'white' }}>EMEL</th>
                                <th style={{ color: 'white' }}>NO. TELEFON</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data?.staffs?.map((item, index) => (
                            <tr key={index}>
                                <td id="linklistp">{item.ordering}</td>
                                <td id="linklistp">
                                    <Link to={`/departments/${state}/${department}/${item.unit_slug}/${item.id}/${item.officer_name_slug}`}>
                                        <img  id="gambardirektorilist" style={{'width' : '140px'}}  src={item.officer_url} />
                                    </Link>
                                </td>
                                <td id="linklistp">
                                    <Link to={`/departments/${state}/${department}/${item.unit_slug}/${item.id}/${item.officer_name_slug}`}>
                                       {item.officer_name}
                                    </Link>
                                </td>
                                <td id="linklistp">{item.officer_position}</td>
                                <td id="linklistp">{item.officer_email}</td>
                                <td id="linklistp">{item.officer_phone}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    </div>
                    </>
                }

            </div>
        );
    }


};

export default DepartmentSection;
