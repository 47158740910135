import LoadMenu from "./LoadMenu"

const Menu1 = () => {
    return (
    <>
    <nav className="navbar navbar-expand-lg navbar-dark" style={{ 'backgroundColor': '#171717' }} id="navbardiatas">

    <a className="navbar-brand" href="/">
        <img
            className="img-responsive"
            style={{ 'marginLeft': '20px' }} // Apply the margin-left style
            src="/img/logortmbaharu2.png"
            alt="Logo RTM"
        />
    </a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbaratas" aria-controls="navbaratas" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbaratas">
            <ul className="navbar-nav ml-auto">
                
                <LoadMenu 
                    title='MENGENAI KAMI'
                    hashtag='mengenai-kami' 
                />

                <LoadMenu 
                    title='PENGUMUMAN'
                    hashtag='pengumuman' 
                />

                <LoadMenu 
                    title='WARGA RTM'
                    hashtag='warga-rtm' 
                />

                <LoadMenu 
                    title='SWASTA/AWAM'
                    hashtag='swasta' 
                />

                <LoadMenu 
                    title='MAKLUMBALAS'
                    hashtag='maklum-balas' 
                />
            </ul>

            <ul className="navbar-nav ml-auto">
                <li><a style={{ 'color':'white'}}  href="https://www.tiktok.com/@radiotelevisyenmalaysia?" className="nav-link font-weight-bold text-uppercase"><img src="/img/tiktok-xxl.png" className="img-fluid" alt="Responsive image"/></a></li>
                <li><a style={{ 'color':'white'}}  href="https://twitter.com/rtm_malaysia?lang=en" className="nav-link font-weight-bold text-uppercase"><img src="/img/x.png" className="img-fluid" alt="Responsive image"/></a></li>
                <li><a style={{ 'color':'white'}}  href="https://www.facebook.com/RadioTelevisyenMalaysia/?locale=ms_MY" className="nav-link font-weight-bold text-uppercase"><img src="/img/facebook.svg" className="img-fluid" alt="Responsive image"/></a></li>
                <li><a style={{ 'color':'white'}}  href="https://www.instagram.com/rtm_malaysia/" className="nav-link font-weight-bold text-uppercase"><img src="/img/instagram.svg" className="img-fluid" alt="Responsive image"/></a></li>
                <li><a style={{ 'color':'white'}}  href="https://www.youtube.com/channel/UCF4KdUqyxJ5Cb0NTGhZXt9g" className="nav-link font-weight-bold text-uppercase"><img src="/img/youtube.png" className="img-fluid" alt="Responsive image"/></a></li>
            </ul>
        </div>
    </nav>
    </>
    )
  };
  
  export default Menu1;