import React from 'react';
import { Card } from 'react-bootstrap';

const Content = ({title, contents, className}) => {
    return (
        <div>

            <Card className={className}>
                <Card.Body>
                    {title}
                    <hr />
                    {contents}
                </Card.Body>
            </Card>
            
        </div>
    );
};

export default Content;