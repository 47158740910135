import LoadFooter from "./LoadFooter"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <>
        <footer className="footer" >
            <div className="container bottom_border" style={{ 'marginTop' : '20px' }} >
                <div className="row" >
                    <div className=" col-sm-6 col-md-3  col-12 col">

                        <ul className="footer_ul_amrc">
                            <li><a target="_blank"  href="https://www.mampu.gov.my/ms/"><img  className="img-fluid" src="/asset/footer/mampu.png" /></a></li>
                            <li><a target="_blank"  href="https://www.malaysia.gov.my/portal/index"><img  className="img-fluid" src="/asset/footer/mygov2.png"/></a></li>
                            <li><a target="_blank"  href="https://ksn.gov.my"><img  className="img-fluid" src="/asset/footer/ksn2.png" /></a></li>
                            <li><a target="_blank"  href="https://mdec.my"><img  className="img-fluid" src="/asset/footer/msc-white.png" /></a></li>
                        </ul>


                    </div>


                    <div className=" col-sm-6 col-md-3  col-12 col">
                        <LoadFooter />
                    </div>


                    <div className=" col-sm-6 col-md-3  col-12 col">
                        <p className="mb10">Radio Televisyen Malaysia<br />
                        Angkasapuri Kota Media<br />
                        50614 Kuala Lumpur</p>

                        <p>aduan [at] rtm.gov.my </p>
                        <p> Tel: 03 - 2282 5333 </p>
                        <p> Faks: 03 - 2284 7591 </p>
                    </div>


                    <div className=" col-sm-6 col-md-3  col-12 col">

                        <ul className="footer_ul_amrc ">
                            <li><a target="_blank" href="https://apps.apple.com/my/app/rtmklik/id777391399"><img  className="img-fluid" src="/asset/footer/apps.png"/></a></li>
                            <li><a target="_blank" href="https://play.google.com/store/apps/details?id=my.gov.rtm.mobile"><img  className="img-fluid" src="/asset/footer/googleplay-bm.png"/></a></li>
                            <li><a target="_blank" href="https://appgallery.huawei.com/app/C101841473"><img  className="img-fluid" src="/asset/footer/huawei_appsgaleryedit1.png"/></a></li>
                        </ul>
                    
                    </div>
                </div>
            </div>


            <div className="container">


                <div className="row" >
                    <div className="col-md-12 d-flex justify-content-center">
                        <p style={{ marginTop: '0.5rem' }}>
                            Hak Cipta Terpelihara @ {currentYear} RADIO TELEVISYEN MALAYSIA
                        </p>
                    </div>
                </div>

            </div>
        </footer>

        </>
    )
}
export default Footer