import React, { useEffect, useState } from 'react'
import axios from '../../libs/axios'
import { useParams } from 'react-router-dom'
import OfficerList from './components/OfficerList'
import BreadCrumb from './components/BreadCrumb'
import ErrorMEssage from './components/ErrorMessage'


const DirectoryOfficerIndex = () => {
    
    const { state } = useParams()
    const { department } = useParams()
    const { section } = useParams()

    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState([])
    const [error, setError] = useState(null)

    /**
     * Load Directory 
     * by state
     */
    useEffect(() => {
        const fetchData = async () => {
            setError(null)
            setData([])
            if(state !== "" && department !== "" && section !== "" ){
                try {
                    setIsloading(true)
                    const response = await axios.get(
                        // get all departments by given :state
                        `${process.env.REACT_APP_BACKEND_URL}/portal/directories/${state}/${department}/${section}`
                    )
                    console.log(response.data);
                    setData(response.data)    
                } catch (error) {
                    setError(error.response.data)
                    //console.log(error.response.data)
                }
                setIsloading(false)
            }
        };
        fetchData() // fetch data from backend
    }, [state,department,section])

    let breadcrumb = {
        "Utama" : "/",
        "Direktori" : "/directories",
        [data?.state]: `/directories/${state}`,
        [data?.department]: `/directories/${state}/${department}`,
        [data?.section]: null
    }
  
    return (
        <>
            <BreadCrumb items={breadcrumb} />
         
            { isLoading ? '...loading' : 
                <>
                { data &&
                    <>     
                    <h1>{data?.section} {data?.unit}</h1>   
                    <hr />
                    {data?.officers?.length > 0 && (
                    <>    
                        <OfficerList items={data.officers} />
                    </>
                    )} 
                    </>
                }    
                </>
            }
            { error &&
                <ErrorMEssage message={error.message} />
            }
        </>
    )
} 
export default DirectoryOfficerIndex