import React from 'react';
import { Card } from 'react-bootstrap';

const HTML = ({contents,className}) => {

    return (
        <>

            { contents.map((item, index) => (
            <div key={index} className={className}>
                {/* <Card.Header>{item.title}</Card.Header> */}
                <div dangerouslySetInnerHTML={{ __html: item.body }} />

            </div>
            ))}

        </>
    );
};

export default HTML;