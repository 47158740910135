import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from "../../libs/axios"

const LoadMenu = ({title,hashtag}) => {

    
    const [data,setData] = useState(null)

    /**
     * Load contents from PageCategory
     * by #hashtag
     */
    useEffect(() => {
        const fetchData = async () => {
  
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/portal/${hashtag}`
                )
                setData(response.data.pageCategories)
                //console.log(response.data.pageCategories)
            } catch (error) {
                console.log(error.response.data)
            }
        };

        fetchData()

    }, [])

    const itemsPerPage = 8; // Number of items per column
    const groupedItems = [];

    // Divide the items into groups
    for (let i = 0; i < data?.length; i += itemsPerPage) {
        groupedItems.push(data?.slice(i, i + itemsPerPage));
    }

    const menuColumns = groupedItems.map((group, index) => (
        <div key={index} className="col">
          <ul className="nav flex-column">
        
            {group?.map((item, itemIndex) => (
              <li
                key={item.id}
                className={`nav-item${itemIndex === group?.length - 1 ? '' : ' border-bottom'}`}
              >
        
        <NavLink
  to={
    item.slug === 'borang-ict' ||
    item.slug === 'tender-sebutharga' ||
    item.slug === 'bahagian' ||
    item.slug === 'siaran-media' ||
    item.slug === 'soalan-lazim' ||
    item.slug === 'maklumat-rancangan-tv-epromo-rtm'
      ? { pathname: `/contents/${item.slug}` }
      : item.slug === 'sistem-eperolehan'
      ? 'https://www.eperolehan.gov.my' // Directly provide the absolute URL
      : { pathname: `/contents/${item.id}/${item.slug}` }
  }
  className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
>
  {item.title}
</NavLink>



              </li>
            ))}
          </ul>
        </div>
      ));
    

    const listItems = data?.map((item, index) => (
        <li key={index} className={`nav-item${index === data?.length - 1 ? '' : ' border-bottom'}`}>
          <NavLink 
              to={`/contents/${item.id}/${item.slug}`} 
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          >
          {item.title}
          </NavLink>
        </li>
      ));
      
    

    return (
        <>
        
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {title}
            </a>
            <div className="dropdown-menu"  aria-labelledby="navbarDropdown" style={{ 'minWidth':'500px'}} >
                <div className="container">
                    <div className="row">
                       
                        {menuColumns}

                    </div>
                </div>
            </div>
        </li>
        </>
    )
}
export default LoadMenu