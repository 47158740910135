import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"

/** Font Awesome **/
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

/** Error **/
import Error404 from "./pages/Error404"

/** LAYOUTS **/
import ContentLayout from "./layouts/ContentLayout"
import HomeLayout from "./layouts/HomeLayout"

/** Pages - PUBLIC **/
import Home from "./pages/Home"
import ContentIndex from "./pages/Contents"

import Content from "./pages/Content"
import Show from "./pages/Show"

import DirectoryIndex from "./pages/Directory/Directory"
import DirectoryDepartments from "./pages/Directory/Departments"
import DirectoryStates from "./pages/Directory/States"
import DirectoryOfficerIndex from "./pages/Directory/OfficerIndex"
import DirectoryOfficerShow from "./pages/Directory/OfficerShow"

import Departments from "./pages/Departments"
import DepartmentStates from "./pages/Departments/States"
import DepartmentIndex from "./pages/Departments/Departments"
import DepartmentSection from "./pages/Departments/Section"
import DepartmentOfficer from "./pages/Departments/Officer"
import SearchResult from "./pages/Departments/SearchResult"

library.add(fas)

export default function App() {
  return (

    // router
    <BrowserRouter>
      <Routes>
        
        <Route element={<HomeLayout />}>
          <Route index element={<Home />} />  
        </Route>

        <Route element={<ContentLayout />}>
   
          <Route path="/contents/:hashtag" element={<ContentIndex />} />
          <Route path="/contents/:pageCategoryId/:slug" element={<Show />} />

          <Route path="/directories" element={<DirectoryIndex /> } />
          <Route path="/directories/:state" element={<DirectoryStates /> } />
          <Route path="/directories/:state/:department" element={<DirectoryDepartments /> } />
          <Route path="/directories/:state/:department/:section" element={<DirectoryOfficerIndex /> } />
          <Route path="/directories/officer/:directoryId" element={<DirectoryOfficerShow /> } />

          <Route path="/departments" element={<Departments /> } />
          <Route path="/departments/:state" element={<DepartmentStates /> } />
          <Route path="/departments/:state/:department" element={<DepartmentIndex /> } />
          <Route path="/departments/:state/:department/:section" element={<DepartmentSection /> } />
          <Route path="/departments/:state/:department/:section/:id/:name" element={<DepartmentOfficer /> } />
          <Route path="/departments/search/:query" element={<SearchResult /> } />
        
        </Route>  

        <Route path="*" element={<Error404 />} />

      </Routes>
    </BrowserRouter>
  );
}

// render
const root = ReactDOM.createRoot(document.getElementById('root')); // public/index.html
root.render(<App />); // render <App/> on public/index.html
