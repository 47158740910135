import React, { useState, useEffect } from 'react';
import axios from '../../libs/axios'
import { Link, useParams } from 'react-router-dom'
import BreadCrumb from '../Directory/components/BreadCrumb'
import './style.css'
import DepartmentSearch from './Search';

const DepartmentStates = () => {

    const [data,setData] = useState([])
    const { state } = useParams()

    useEffect( () => {
        axios(`${process.env.REACT_APP_BACKEND_URL}/departments/${state}`)
        .then(
            response => {
                //console.log(response)
                setData(response.data)
            }
        )
        .catch()
    },[state])



    //console.log(data)
    
    const breadcrumb = [
        { text: "Utama", link: "/" },
        { text: "Direktori", link: "/departments" },
        { text: data?.state, link: null },

      ];


    if(state){ 
        return (
            <div>

                <BreadCrumb items={breadcrumb} />
                <DepartmentSearch />
                <h1 style={{"margin-top":"2rem"}}>Direktori {data?.state}</h1><br />
                <ul className="list-group">
                {data?.departments?.map((item, index) => (
                    <li className="list-group-item  border-0" key={index}>
                        <Link id="linkdirektori" to={`/departments/${state}/${item.department_slug}`}>
                            <h3 id="linkdirektori">{item.department}</h3>
                        </Link>
                    </li>
                ))}
                </ul> 


            </div>
                       
        );
    }
};

export default DepartmentStates;